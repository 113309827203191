<!--充值管理-->
<template>
  <div class="fundaccount">
    <el-form :model="listQuery" ref="listQuery" label-width="auto" style="text-align: left" :rules="rules">
      <el-form-item :label="$t('wallet.russia')">
        {{ listQuery.B$ }} {{ this.wallet }}
      </el-form-item>
      <!--     金额 -->
      <el-form-item :label="$t('Account.money')+(':')" prop="money">
        <el-input style="width: 50%" v-model="listQuery.money" @blur="numberToCurrencyNo" type="number"
                  clearable
                  :placeholder="$t('login.pass.amount')"/>  MXN
        &nbsp;
        <span v-if="this.companyCode !== 'KGZ'"> {{ this.symbol }}</span>
        <img v-if="this.companyCode === 'KGZ'" style="width: 12px; height: 17px"
             :src="require('@/assets/images/kgz.jpg')">
        <span style="color: green;margin-left: 50px;font-size:18px">{{ this.moneyNum }}  MXN</span>
      </el-form-item>
      <!--      公司名称-->
      <el-form-item v-if="this.listQuery.tranType === '0'" :label="$t('corporate.name')+(':')">
        {{this.listQuery.companyName}}
      </el-form-item>
      <!--      银行账户-->
      <el-form-item v-if="this.listQuery.tranType === '0'" :label="$t('Bank.account')+(':')">
        {{this.listQuery.bankKey}}
      </el-form-item>
      <!--      开户银行-->
      <el-form-item v-if="this.listQuery.tranType === '0'" :label="$t('bank.of.deposit')+(':')">
        {{this.listQuery.bankValue}}
      </el-form-item>
      <!--      银行代码-->
      <el-form-item v-if="this.listQuery.tranType === '0'" :label="$t('Bank.code')+(':')">
        {{this.listQuery.bankCode}}
      </el-form-item>
      <!--      汇款人-->
      <el-form-item v-if="this.listQuery.tranType === '0'" :label="$t('remitter')+(':')" required>
        <el-input v-model="listQuery.payer" />
      </el-form-item>
      <!--      备注-->
      <el-form-item :label="$t('pmProductSppm.remark')+(':')">
        <el-input v-model="listQuery.memo" type="textarea" :autosize="{ minRows: 2, maxRows: 4}" clearable
                  :placeholder="$t('')"/>
      </el-form-item>
      <!--      交易密码-->
      <el-form-item :label="$t('transaction.password')+(':')" prop="payPassword">
        <el-input :placeholder="$t('login.pass.input')" v-model="listQuery.payPassword"
                  show-password></el-input>
      </el-form-item>
      <!--      付款方式-->
      <el-form-item :label="$t('fiPayAdvice.dealType')+(':')" prop="tranType">
        <el-radio-group v-model="listQuery.tranType" @change="tranTypeChange">
          <el-radio v-for="role in transTypeList" :key="role.code" :label="role.code" border
                    style="display: block;margin-left: 0;margin-top: 10px" >
            <template>
              {{ $t(role.title) }}
              <img v-if="role.code==='1' && companyCode === 'LTV'" :src="require('@/assets/images/paysera.png')" alt="paySera"
                   style="width: 80px;height: 19px;vertical-align: top">
              <img v-if="role.code==='1' && companyCode === 'TRA'" :src="require('@/assets/images/ipara.png')" alt="paySera"
                   style="width: 80px;height: 19px;vertical-align: top">
              <img v-if="role.code==='8'" :src="require('@/assets/images/visa.png')" alt="visa"
                   style="width: 54px;height: 20px;vertical-align: top">
              <img v-if="role.code==='8'" :src="require('@/assets/images/mastercord.jpg')" alt="mastercord"
                   style="width: 32px;height: 26px;vertical-align: top">
            </template>
          </el-radio>
        </el-radio-group>
      </el-form-item>
      <!-- 土耳其在线支付 内容 -->
      <div v-if="companyCode === 'TRA' && listQuery.tranType === '1'">
        <!-- 持卡人姓 -->
        <el-form-item :label="$t('Cardholder.surname')+(':')" required>
          <el-input v-model="listQuery.surname" />
        </el-form-item>
        <!-- 持卡人姓名 -->
        <el-form-item :label="$t('Cardholder.full_name')+(':')" required>
          <el-input v-model="listQuery.nameOnCard" />
        </el-form-item>
        <!-- 卡号 -->
        <el-form-item :label="$t('title.cardNo')+(':')" required>
          <el-input v-model="listQuery.cardAsn" />
        </el-form-item>
        <!-- 卡CVC -->
        <el-form-item :label="$t('Card.cvc')+(':')" required>
          <el-input v-model="listQuery.cardCvc" />
        </el-form-item>
        <!-- 持卡人邮箱 -->
        <el-form-item :label="$t('Cardholder.mailbox')+(':')" required>
          <el-input v-model="listQuery.email" />
        </el-form-item>
        <!-- 卡到期年份 -->
        <el-form-item :label="$t('Card.expiration_year')+(':')" required>
          <el-select v-model="listQuery.cardExpireYear" placeholder="">
            <el-option v-for="item in YearList" :key="item" :label="item" :value="item.toString().substr(2,2)"/>
          </el-select>
        </el-form-item>
        <!-- 卡到期月份 -->
        <el-form-item :label="$t('Card.expiration_month')+(':')" required>
          <el-select v-model="listQuery.cardExpireMonth" placeholder="">
            <el-option
              v-for="item in MonthList"
              :key="item"
              :label="item"
              :value="item">
            </el-option>
          </el-select>
        </el-form-item>
      </div>
      <!--      已有信用卡-->
      <el-form-item v-if="listQuery.tranType==='8'" :label="$t('Existed_Credit_Card')+(':')">
        <el-radio-group v-model="listQuery.creditCard">
          <el-radio v-for="role in creditCardList" :key="role.id" :label="role.id" border
                    style="display: block;margin-left: 0;margin-top: 10px">
            <template>
              {{ $lt(initDict.credit_card_type, role.cardType) }}&nbsp;&nbsp;&nbsp;{{ $t(role.cardAsn) }}&nbsp;&nbsp;&nbsp;{{ role.nameOnCard }}
            </template>
          </el-radio>
        </el-radio-group>
      </el-form-item>
      <!--      卡类型-->
      <el-form-item v-if="listQuery.creditCard==='aaa'&&listQuery.tranType==='8'"
                    :label="$t('Card_Type')+(':')" prop="cardType" required>
        <el-select v-model="listQuery.cardType">
          <el-option v-for="item in creditCardTypeList" :key="item.code"
                     :label="$lt(initDict.credit_card_type, item.code)" :value="item.code"/>
          <!--<el-option value="3" label="银联卡(网关模式)"></el-option>-->
          <!--<el-option value="111" label="银联卡(云闪付二维码)"></el-option>-->
          <!--<el-option value="150" label="万事达卡"></el-option>-->
          <!--<el-option value="6" label="VISA 卡"></el-option>-->
          <!--<el-option value="10" label="网关模式（万事达卡，VISA）"></el-option>-->
        </el-select>
      </el-form-item>
      <!--      是否保存-->
      <el-form-item v-if="listQuery.creditCard==='aaa'&&listQuery.tranType==='8'"
                    :label="$t('Save_or_Not')+(':')">
        <el-select v-model="listQuery.isSave">
          <el-option v-for="item in yesNoList" :key="item.code"
                     :label="$lt(initDict.yesNo, item.code)" :value="item.code"/>
        </el-select>
      </el-form-item>
      <!--      卡号-->
      <el-form-item
        v-if="listQuery.creditCard==='aaa'&&listQuery.tranType==='8'&&(listQuery.cardType==='3'||listQuery.cardType==='6'||listQuery.cardType==='150'||listQuery.cardType==='10')"
        :label="$t('title.cardNo')+(':')" prop="cardAsn" required>
        <el-input v-model="listQuery.cardAsn"></el-input>
      </el-form-item>
      <!--      cvv-->
      <el-form-item
        v-if="listQuery.creditCard==='aaa'&&listQuery.tranType==='8'&&(listQuery.cardType==='6'||listQuery.cardType==='150')"
        label="cvv:"
        prop="cvv" required>
        <el-input v-model="listQuery.cvv"></el-input>
      </el-form-item>
      <!--      有效期-->
      <el-form-item
        v-if="listQuery.creditCard==='aaa'&&listQuery.tranType==='8'&&(listQuery.cardType==='6'||listQuery.cardType==='150')"
        :label="$t('Validity_Date')+(':')"
        required>
        <el-select v-model="listQuery.validityMonth">
          <el-option v-for="item in MonthList" :key="item" :label="item" :value="item"/>
        </el-select>
        &nbsp;-&nbsp;
        <el-select v-model="listQuery.validityYear">
          <el-option v-for="item in YearList" :key="item" :label="item" :value="item"/>
        </el-select>
      </el-form-item>
      <!--      持卡人姓名-->
      <el-form-item
        v-if="listQuery.creditCard==='aaa'&&listQuery.tranType==='8'&&(listQuery.cardType==='6'||listQuery.cardType==='150')"
        :label="$t('Cardholder_Name')+(':')"
        prop="nameOnCard" required>
        <el-input v-model="listQuery.nameOnCard"></el-input>
      </el-form-item>
      <!--      持卡人手机-->
      <el-form-item
        v-if="listQuery.creditCard==='aaa'&&listQuery.tranType==='8'&&(listQuery.cardType==='10'||listQuery.cardType==='6'||listQuery.cardType==='150')"
        :label="$t('Cardholder_Cellphone')+(':')" prop="mobile" required>
        <el-input v-model="listQuery.mobile"></el-input>
      </el-form-item>
      <!--      持卡人邮箱-->
      <el-form-item
        v-if="listQuery.creditCard==='aaa'&&listQuery.tranType==='8'&&(listQuery.cardType==='10'||listQuery.cardType==='6'||listQuery.cardType==='150')"
        :label="$t('Cardholder_Email')+(':')" prop="email" required>
        <el-input v-model="listQuery.email"></el-input>
      </el-form-item>
    </el-form>
    <span style="float: right">
      <el-button size="mini" :loading="btnloading" type="warning" @click="confirmBtn('listQuery')"
                 plain>{{ $t('btn.implement') }}</el-button>
    </span>
    <div v-show="false">
      <form id="onlineForm" :action="onlineForm.postUrl"  method="POST">
        <input v-if="companyCode === 'LTV'" type="hidden" name="data" :value="onlineForm.data"/>
        <input v-if="companyCode === 'LTV'" type="hidden" name="sign" :value="onlineForm.sign"/>
        <input v-if="companyCode === 'TRA'" type="text" name='parameters' :value="onlineForm.parameters"/>
      </form>
    </div>
  </div>
</template>

<script>
  import {mapGetters} from "vuex";
  import {creditCardShowI, getPersonalData2, saveOrUpdateFiAcInput, sysBanklist} from "@/api/rus/api";

  export default {
    name: "FundAccount",
    data() {
      const validateMoney = (rule, value, callback) => {
        if (value <= 0) {
          return callback(new Error(this.$t('po.setInfo')))
        } else {
          callback()
        }
      }
      return {
        creditCardList: [],
        MonthList: ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'],
        YearList: [],
        symbol: null,
        wallet: null,
        electronics: null,
        onlineForm: {
          postUrl: null,
          data: null,
          sign: null,
          parameters: null
        },
        listQuery: {
          cardType: '10',
          isSave: null,
          creditCard: null,
          validityYear: null,
          validityMonth: null,
          F$: 0,
          B$: 0,
          money: 0,
          payPassword: '',
          tranType: '',
          nameOnCard: '',
          cardAsn: '',
          cardExpireMonth: '',
          cardExpireYear: '',
          cardCvc: '',
          surname: '',
          email: '',
          cvv: '',
          payer: '',
          mobile: ''
        },
        moneyNum: 0,
        btnloading: false,
        rules: {
          money: [
            {required: true, validator: validateMoney, trigger: ['change', 'blur']}
          ],
          payPassword: [
            {required: true, message: this.$t('login.pass.input'), trigger: ['change', 'blur']}
          ],
          tranType: [
            {required: true, message: this.$t('login.pass.payment.method'), trigger: ['change', 'blur']}
          ],
          acType: [
            {required: true, message: this.$t('login.pass.payment.method'), trigger: ['change', 'blur']}
          ],
        },
        initDict: {
          yesNo: 'yesno',
          fiPayAdvicedealType: 'trans_type_cn',
          credit_card_type: 'credit_card_type'
        },
      }
    },
    computed: {
      ...mapGetters([
        'language',
        'userCode',
        'username',
        'companyCode',
      ]),
      transTypeList() {
        if(this.companyCode === 'LTV'){
          return this.$ll(this.initDict.fiPayAdvicedealType).filter(ele => ele.code === '0' || ele.code === '1' || ele.code === '2' || ele.code === '8')
        } else if(this.companyCode === 'TRA') {
          return this.$ll(this.initDict.fiPayAdvicedealType).filter(ele => ele.code === '0' ||  ele.code === '1' || ele.code === '2')
        } else {
          return this.$ll(this.initDict.fiPayAdvicedealType).filter(ele => ele.code === '0' || ele.code === '2')
        }
      },
      creditCardTypeList() {
        return this.$ll(this.initDict.credit_card_type)
      },
      yesNoList() {
        return this.$ll(this.initDict.yesNo)
      }
    },
    created() {
      this.getCreditCardShowI()
      this.getBalance()
      this.getSymbol()
      for (let i = 2023; i < 2101; i++) {
        this.YearList.push(i)
      }
    },
    methods: {
      tranTypeChange() {
         this.listQuery.nameOnCard = ''
         this.listQuery.cardAsn = ''
         this.listQuery.cardExpireMonth = ''
         this.listQuery.cardExpireYear = ''
         this.listQuery.cardCvc = ''
         this.listQuery.surname = ''
         this.listQuery.email = ''
         this.listQuery.creditCard = ''
         this.listQuery.cardType = ''
         this.listQuery.isSave = ''
         this.listQuery.cvv = ''
         this.listQuery.validityMonth = ''
         this.listQuery.validityYear = ''
         this.listQuery.mobile = ''
         this.listQuery.payer = ''
      },
      getCreditCardShowI() {
        creditCardShowI().then(res => {
          this.creditCardList = res.data
          this.creditCardList.push({id: 'aaa', cardAsn: this.$t('Use_New_Card')})
        }).catch(err => {
          console.warn(err);
        })
        sysBanklist().then(res=>{
          this.listQuery.bankKey = res.data[0].bankKey
          this.listQuery.bankValue = res.data[0].bankValue
          this.listQuery.bankCode = res.data[0].bankCode
          this.listQuery.companyName = res.data[0].companyName
        }).catch(err=>{
          console.error(err)
        })
      },
      numberToCurrencyNo() {
        if (!this.listQuery.money) return 0
        // 获取整数部分
        const intPart = Math.trunc(this.listQuery.money)
        // 整数部分处理，增加,
        const intPartFormat = intPart.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,')
        // 预定义小数部分
        let floatPart = ''
        // 将数值截取为小数部分和整数部分
        const valueArray = this.listQuery.money.toString().split('.')
        if (valueArray.length === 2) { // 有小数部分
          floatPart = valueArray[1].toString() // 取得小数部分
          return this.moneyNum = intPartFormat + '.' + floatPart
        }
        return this.moneyNum = intPartFormat + floatPart
      },
      getSymbol() {
        if (this.companyCode === 'RUS') {
          //单位(货币)
          this.symbol = 'Руб'
          this.electronics = '$'
          //符号
          this.wallet = '₽'
        } else if (this.companyCode === 'MNG') {
          this.symbol = 'MNG'
          this.wallet = '₮'
        } else if (this.companyCode === 'KAZ') {
          this.symbol = 'Тг'
          this.wallet = '₸'
        } else if (this.companyCode === 'KGZ') {
          this.symbol = 'Сом'
          this.wallet = 'лв'
        } else if (this.companyCode === 'TJK') {
          this.symbol = 'somoni'
          this.wallet = 'SM'
        }
      },
      getBalance() {
        getPersonalData2({'userCode': this.userCode}).then(res => {
          for (let i = 0; i < res.data.length; i++) {
            if (res.data[i].acType === 'B$') {
              this.listQuery.B$ = res.data[i].validBalance
            }
            if (res.data[i].acType === 'F$') {
              this.listQuery.F$ = res.data[i].validBalance
            }
          }
        })
      },
      confirmBtn(listQuery) {
        this.listQuery.userCode = this.userCode
        this.listQuery.username = this.username
        this.listQuery.payNum = this.listQuery.bankKey
        this.listQuery.acType = 'B$'
        this.btnloading = true
        this.listQuery.rate = 1
        if (this.listQuery.tranType === '0') {
          if (this.listQuery.payer === '') {
            this.$message.info(this.$t('po.setInfo') + this.$t('remitter')) // 请填写汇款人
            this.btnloading = false
            return false;
          }
        }
        if (this.listQuery.tranType === '8') {
          //信用卡支付
          this.listQuery.validity = this.listQuery?.validityMonth + '-' + this.listQuery?.validityYear
          // console.log(this.listQuery.validity, 'this.listQuery.validity')
          if (this.listQuery.creditCard !== 'aaa') { //选择已有信用卡时
            let selectObj = this.creditCardList.filter(item => item.id === this.listQuery.creditCard)[0]
            // console.log(selectObj, '选择的信用卡信息')
            this.listQuery = {...this.listQuery, ...selectObj}
            this.listQuery.isSave = 0
            this.listQuery.id = null
            // console.log(this.listQuery, '传递的参数')
          }
          // 网关模式时 删除有效期
          if (this.listQuery.cardType==10) {
            delete this.listQuery.validity
          }
        }
        if (this.listQuery.tranType === '1' && this.companyCode === 'TRA') {
          if (this.listQuery.surname === '') {
            this.$message.info(this.$t('po.setInfo') + this.$t('Cardholder.surname')) // 请填写持卡人姓
            this.btnloading = false
            return false;
          }
          if (this.listQuery.nameOnCard === '') {
            this.$message.info(this.$t('po.setInfo') + this.$t('Cardholder.full_name')) // 请填写持卡人姓名
            this.btnloading = false
            return false;
          }
          if (this.listQuery.cardAsn === '') {
            this.$message.info(this.$t('po.setInfo') + this.$t('title.cardNo')) // 请填写卡号
            this.btnloading = false
            return false;
          }
          if (this.listQuery.cardCvc === '') {
            this.$message.info(this.$t('po.setInfo') + this.$t('Card.cvc')) // 请填写卡CVC
            this.btnloading = false
            return false;
          }
          if (this.listQuery.email === '') {
            this.$message.info(this.$t('po.setInfo') + this.$t('Cardholder.mailbox')) // 请填写持卡人邮箱
            this.btnloading = false
            return false;
          }
          if (this.listQuery.cardExpireMonth === '') {
            this.$message.info(this.$t('po.setInfo') + this.$t('Card.expiration_month')) // 请填写卡到期月份
            this.btnloading = false
            return false;
          }
          if (this.listQuery.cardExpireYear === '') {
            this.$message.info(this.$t('po.setInfo') + this.$t('Card.expiration_year')) // 请填写卡到期年份
            this.btnloading = false
            return false;
          }
        }
        this.$refs[listQuery].validate((valid) => {
          if (valid) {
            this.$confirm(this.$t('fi.sure.handle'), this.$t('common.tips'), {
              confirmButtonText: this.$t('button.confirm'),
              cancelButtonText: this.$t('operation.button.cancel'),
              customClass: 'msgBox',
              type: 'warning'
            }).then(() => {
              saveOrUpdateFiAcInput({...this.listQuery}).then(res => {
                if (this.listQuery.tranType === '1') {
                  this.onlineForm.postUrl = res.data.postUrl
                  this.onlineForm.data = res.data.data
                  this.onlineForm.sign = res.data.sign
                  this.onlineForm.parameters = res.data.data.replace(/\\"/g, '"')
                  setTimeout(() => {
                    document.getElementById('onlineForm').submit()
                  }, 1000)
                } else if (this.listQuery.tranType === '8') {
                  this.btnloading = false
                  // console.log('信用卡支付', res.data)
                  if (res.data.status == 0) {
                    // window.open(res.data?.postUrl, '_blank')
                    window.location.href = res.data?.postUrl
                    // this.$router.push({name: 'AccountHistory'})
                  } else {
                    this.$alert(res.data?.errManages || res?.data, 'Error', {
                      confirmButtonText: this.$t('button.confirm').toString(),
                      type: 'error',
                      callback: () => {
                        this.$message({
                          type: 'error',
                          message: res.data?.errManages || res?.data
                        });
                      }
                    });
                  }
                } else {
                  this.$router.push({name: 'AccountHistory'})
                  this.$message.success(this.$t('bdSendRegister.operaterSuccess'))
                  this.btnloading = false
                }
              }).catch(() => {
                this.btnloading = false
                this.$message.error(this.$t('bdSendRegister.operaterFail'))
              })
            }).catch(() => {
              this.btnloading = false
              this.$message({
                type: 'info',
                message: this.$t('operation.button.cancel')
              })
            })
          } else {
            this.btnloading = false
          }
        })
      },
      resetForm(listQuery) {
        this.$refs[listQuery].resetFields()
      }
    }
  }
</script>

<style scoped>
  ::v-deep input::-webkit-outer-spin-button,
  ::v-deep input::-webkit-inner-spin-button {
    -webkit-appearance: none !important;
  }

  ::v-deep input[type='number'] {
    -moz-appearance: textfield !important;
  }
</style>

